import React from "react";

import ecoute_img from "../assets/img/question.png"; 
import employability_img from "../assets/img/deskchair.png";
import accompagnement_img from "../assets/img/ecosystem.png";

import formation1_img from "../assets/img/idea.jpg";
import formation2_img from "../assets/img/group.jpg";
import formation3_img from "../assets/img/training.jpg";
import formation4_img from "../assets/img/plan.jpg";
import Footer from "../components/footer";



import espace from "../assets/img/11.jpg";
import logo_indh from "../assets/img/logo_indhl.png";
import comunel from "../assets/img/com-l.png";

import plj_logo from "../assets/img/logoo.png";





function Cadre() {
    return (
        <div>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="shortcut icon" href="assets/img/logo_ppj.png" type="image/x-icon" />
        <title> </title>
        <link href="https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700&display=swap" rel="stylesheet" />
        <link rel="stylesheet" href="css/styles.css" />
        <div className="relative bg-[#1b3f7d]/80">
          <header>
            <div className="max-w-6xl mx-auto flex items-center justify-between px-4 sm:px-8 py-3">
              <button className="block lg:hidden border border-white/25 rounded px-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-8 h-8 text-white/50">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </button>
              <div>
              <a className="flex items-center" href="/#">
                  <img className="w-20 h-19 pl-6" src={plj_logo} alt="Plateforme des jeunes de Temara" />
                  <span className="uppercase text-sm font-bold text-white">منصة الشباب <br /> <span className="bg-brand-yellow text-brand-black rounded text-[10px] py-0.5 px-1">العيون</span></span>
                </a>
              </div>
              <div className="hidden lg:block text-white">
                <a className="hover:opacity-75 transition ease-linear p-2" href="/programmes/all/">البرامج </a>
                <a className="hover:opacity-75 transition ease-linear p-2" href="/etapesintegration/">مراحل
                  الانخراط</a>
                <a className="hover:opacity-75 transition ease-linear p-2" href="/contact/">تواصل معنا</a>
{/*                 <input className="border border-white rounded hover:bg-white hover:text-brand-dark-blue transition ease-linear cursor-pointer py-1.5 px-3 mr-3" type="submit" defaultValue="fr" />
 */}                <a className="bg-brand-yellow rounded hover:bg-brand-dark-yellow transition ease-linear mr-6 py-2.5 px-3" href="http://app.plj-laayoune.ma/">تسجيل الدخول</a>
              </div>
            </div>
          </header>
          {/* hero */}
          <section className="max-w-6xl mx-auto px-4 sm:px-8 py-16">
            <div className="lg:flex items-center justify-between">
              <div className="lg:w-1/2">
                <h1 className="font-light text-white text-6xl text-center lg:text-right pb-4">منصة الشباب <span className="text-[#f39c12]">العيون</span></h1>
                <h2 className="font-light text-xl text-white text-center lg:text-right pb-12">فضاء من أجل الرفع من <strong className="font-weight-bold"> قابلية التشغيل و إنشاء
                    المقاولات</strong> <br /> لدى الشباب</h2>
              </div>
              <div className="lg:w-1/2 sm:flex items-center">
                <div className="sm:w-1/2 bg-white rounded-xl p-7">
                  <p className="text-xl text-center text-brand-slate/80 pb-2">بمبادرة من</p>
                  <img className="sm:w-40 mx-auto py-2" src={logo_indh} alt="" />
                  <p className="text-xl text-center text-brand-slate/80 pt-4 pb-2">بشراكة مع</p>
                  <div className="grid grid-cols-2 gap-3">
                    <img className="sm:w-[74px]" src="assets/img/pref.jpeg" alt="" />
                    <img className="sm:w-[40px] m-auto" src={comunel} alt="" />
                    <div />
                    <img className="w-16 sm:w-11 mx-auto" src="assets/img/anapec.png" alt="" />
                  </div>
                </div>
                <div className="sm:w-1/2 space-y-4 mr-4">
                  <div className="bg-white rounded-b-xl ">
                    <img src={espace} alt="" />
                    <div className="flex items-center p-2">
                      <span className="w-8 h-8 rounded-full bg-brand-blue-2 flex items-center justify-center text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-heart">
                          <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
                          </path>
                        </svg>
                      </span>
                      <p className="text-sm text-brand-slate/80 pr-4">فضاء مندمج وملائم</p>
                    </div>
                  </div>
                  <div className="bg-white rounded-xl flex items-center p-2">
                    <span className="w-8 h-8 rounded-full bg-brand-yellow flex items-center justify-center text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                        <polyline points="20 6 9 17 4 12" />
                      </svg>
                    </span>
                    <p className="text-sm text-brand-slate/80 pr-4">موجه للشباب من 18 إلى 45 سنة</p>
                  </div>
                  
                </div>
              </div>
            </div>
          </section>
          <div className="min-h-[20px] absolute inset-x-0 bottom-0 sm:-mb-px w-full">
            <svg className="max-w-full w-full h-5 sm:h-full fill-white" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 2560 100" style={{enableBackground: 'new 0 0 2560 100'}} xmlSpace="preserve">
              <polygon points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </div>
        <section className="p-16">
          <blockquote className="text-center">
            <p className="font-light text-40 pb-4">غير خاف عليكم أن الاستثمار في الجوانب اللامادية للتنمية البشرية [...] يشكل المنطلق الحقيقي
              والقاعدة الأساسية لبناء مغرب الغد</p>
            <footer className="text-brand-dark-blue">جلالة الملك محمد السادس</footer>
          </blockquote>
        </section>
       
        <section className="max-w-6xl mx-auto px-4 pb-10 pt-28">
          <div className="flex lg:justify-center pb-1">
            <p className="inline bg-brand-yellow font-bold text-brand-black rounded text-xs py-0.5 px-1">على أرض الواقع؟</p>
          </div>
          <h3 className="text-40 lg:text-center pb-6">توجه عملي</h3>
          <p className="font-light text-xl opacity-80 lg:text-center md:px-4 pb-4">نبذة عن الخدمات المقدمة داخل المنصة</p>
          <div className="max-w-[610px] lg:max-w-full mx-auto">
            {/* step-1 */}
            <div className="relative flex flex-col lg:flex-row items-center justify-center py-14 lg:py-20">
              <div className="lg:w-1/2 order-last lg:order-first">
                <img className="w-full lg:w-[475px] rounded-lg card" src={formation1_img} alt="" />
              </div>
              <div className="lg:w-1/2">
                <div className="lg:max-w-[410px] mx-auto">
                  <h3 className="font-medium text-3xl pt-6 pb-2">برامج تدريبية مكثفة و تقنية</h3>
                  <p className="font-light text-xl leading-8 py-6">مبرمجة في إطار مسارات قصيرة المدى، التدريبات تمنحك الفرصة لملاءمة كفاءاتك مع متطلبات
                    السوق
                    أو مساعدتك على تحقيق مشروعك. </p>
                </div>
              </div>
              <svg className="hidden lg:block absolute -bottom-12 left-1/2 -translate-x-1/2 rotate-45" width="355px" height="103px" viewBox="0 0 355 103" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" strokeDasharray="6,12" strokeLinecap="round">
                  <path stroke="#E2E8F0" strokeWidth={3} transform="translate(173.245679, 51.548257) scale(-1, 1) translate(-173.245679, -51.548257)" d="M-6.75432109,1.54825684 C113.245679,110.326533 233.245679,130.359459 353.245679,61.647035" />
                </g>
              </svg>
            </div>
            {/* step-2 */}
            <div className="relative flex flex-col lg:flex-row items-center justify-center py-14 lg:py-20">
              <div className="lg:w-1/2">
                <div className="lg:max-w-[410px]">
                  <h3 className="font-medium inline text-3xl pt-6 pb-2">أنشطة لدعم المجهودات المقاولاتية -</h3>
                  <p className="inline font-light text-xl leading-8">تعاونيات، أنشطة مذرة للدخل، مقاولة ذاتية ...</p>
                  <p className="font-light text-xl leading-8 py-6">دراسات الجدوى، خطط العمل، تسهيل ولوج السوق، إيجاد موارد مالية أو مجرد إيجاد فضاء
                    خدماتي للعمل: خدمات تجدها بالمنصة و
                    تقوي حظوظك في تسهيل إنجاح مشروعك.</p>
                </div>
              </div>
              <div className="lg:w-1/2">
                <img className="w-full lg:w-[475px] rounded-lg card" src={formation2_img} alt="" />
              </div>
              <svg className="hidden lg:block absolute -bottom-12 left-1/2 -translate-x-1/2 -rotate-12" width="355px" height="103px" viewBox="0 0 355 103" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" strokeDasharray="6,12" strokeLinecap="round">
                  <path stroke="#E2E8F0" strokeWidth={3} transform="translate(173.245679, 51.548257) scale(-1, 1) translate(-173.245679, -51.548257)" d="M-6.75432109,1.54825684 C113.245679,110.326533 233.245679,130.359459 353.245679,61.647035" />
                </g>
              </svg>
            </div>
            {/* step-3 */}
            <div className="relative flex flex-col lg:flex-row items-center justify-center py-14 lg:py-20">
              <div className="lg:w-1/2 order-last lg:order-first">
                <img className="w-full lg:w-[475px] rounded-lg card" src={formation3_img} alt="" />
              </div>
              <div className="lg:w-1/2">
                <div className="lg:max-w-[410px] mx-auto">
                  <h3 className="font-medium text-3xl pt-6 pb-2">أنشطة لتنمية القدرات الذاتية</h3>
                  <p className="font-light text-xl leading-8 py-6">بفضل مدربي وموجهي المنصة، بإمكانك إكتساب كفاءات شاملة، وتنمية قدراتك الحياتية، في إطارٍ تعاطفي وممتع.</p>
                </div>
              </div>
              <svg className="hidden lg:block absolute -bottom-12 left-1/2 -translate-x-1/2 rotate-45" width="355px" height="103px" viewBox="0 0 355 103" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" strokeDasharray="6,12" strokeLinecap="round">
                  <path stroke="#E2E8F0" strokeWidth={3} transform="translate(173.245679, 51.548257) scale(-1, 1) translate(-173.245679, -51.548257)" d="M-6.75432109,1.54825684 C113.245679,110.326533 233.245679,130.359459 353.245679,61.647035" />
                </g>
              </svg>
            </div>
            {/* step-4 */}
            <div className="relative flex flex-col lg:flex-row items-center justify-center py-14 lg:py-20">
              <div className="lg:w-1/2">
                <div className="lg:max-w-[410px]">
                  <h3 className="font-medium text-3xl pt-6 pb-2">ربط علاقات مع خبراء، إكتشاف طرق عمل جديدة، مواكبة و توجيه فردي...</h3>
                  <p className="font-light text-xl leading-8 py-6"></p>
                </div>
              </div>
              <div className="lg:w-1/2">
                <img className="w-full lg:w-[475px] rounded-lg card" src={formation4_img} alt="" />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    )
}

export default Cadre
