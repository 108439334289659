import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

// import all images fron /img/gallery/01 to 80  automatically
const importAll = (r) => {
    return r.keys().map(r);
    };
    const images = importAll(
    require.context("../assets/img/gallery", false, /\.(png|jpe?g|jpg)$/)
    );

    const photos = images.map((image, index) => {
    return {
        src: image,
        width: 4,
        height: 3,
    };
    });





function Carousell() {



  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

    return ( 
        <div>
            <Gallery photos={photos} onClick={openLightbox}
            layout="masonry"
            />

      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox} >
            <Carousel
              currentIndex={currentImage}
              styles={{
                view: base => ({
                    ...base,
                    position: "relative",
                    '& > img': {
                        objectFit: "contain",
                        maxHeight: "100%",
                        maxWidth: "100%",
                        margin: "auto",
                    },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",


                    
                })
                }}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
        </div>
        );
}

export default Carousell;