import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import Home from './pages/home';
import Contact from './pages/contact';
import EtapesIntegration from './pages/etapes-integration';
import Programmes from './pages/programmes';
import Cadre from './pages/cadre';
import Newhome from './pages/new_home';
import Mediatec from './pages/mediatec';
import Login from './pages/login';
import Register from './pages/register';
import Dashboard from './pages/dashboard';
import Profile from './pages/profile';
import Blogpost from './pages/blog-details';

function App() {
  return (
    <div className="App font-almarai text-brand-black" dir="rtl">
              <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/etapesintegration" element={<EtapesIntegration />} />
        <Route path="/programmes" element={<Programmes />} />
        <Route path="/cadre" element={<Cadre />} />
{/*         <Route path="/gallery" element={<Mediatec />} />
 */}        <Route path="/home" element={<Newhome />} />
{/*         <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} /> */}
        <Route path="/blog*" element={<Blogpost />} />
        
        </Routes>


    </div>
  );
}

export default App;
