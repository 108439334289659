import React from "react";
import plj_logo from "../assets/img/logoo.png";

function Headerintra() {

    return (
        <div>
        <header className="shadow-lg">
          <div className="max-w-6xl mx-auto flex items-center justify-between px-4 sm:px-8 py-3">
            <button className="block lg:hidden border border-brand-black/20 rounded px-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-8 h-8">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </button>
            <div>
            <a className="flex items-center" href="#">
                  <img className="w-20 h-19 pl-6" src={plj_logo} alt="Plateforme des jeunes de Temara" />
                  <span className="uppercase text-sm font-bold text-black">منصة الشباب <br /> <span className="bg-brand-yellow text-brand-black rounded text-[10px] py-0.5 px-1">العيون</span></span>
                </a>
            </div>
            <div className="hidden lg:block">
              <a className="opacity-50 hover:opacity-100 transition ease-linear p-2" href="#">الصفحة الرئيسية</a>
              <a className="opacity-50 hover:opacity-100 transition ease-linear p-2" href="/programmes/">البرامج </a>
              <a className="opacity-50 hover:opacity-100 transition ease-linear p-2" href="/etapesintegration/">مراحل الانخراط</a>
              <a className="opacity-50 hover:opacity-100 transition ease-linear p-2" href="/contact/">تواصل معنا</a>
{/*               <input className="border border-brand-dark-blue rounded hover:bg-brand-dark-blue hover:text-white transition ease-linear py-1.5 px-3 mr-3" type="submit" defaultValue="fr" />
 */}            </div>
            <div className="hidden lg:block">
              <a className="text-white bg-brand-yellow rounded hover:bg-brand-dark-yellow transition ease-linear mr-6 py-2.5 px-3" href="http://app.plj-laayoune.ma/">تسجيل الدخول</a>
            </div>
          </div>
        </header>
        {/* hero */}
      
       

      </div>
    );

}

export default Headerintra;
