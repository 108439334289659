import React from "react";
import HeaderPages from "../components/headerpages";
import Footer from "../components/footer";

const Blogpost = () =>  {
    return (
        <div>
            <HeaderPages />
            <div className="max-w-6xl mx-auto px-4 sm:px-8 py-8">
                <h1 className="font-MEDIUM text-2xl pt-1">Blog Post</h1>
            </div>

            {/* blog article goes here using tailwind and dummy text from https://www.lipsum.com/*/}
            <div className="max-w-6xl mx-auto px-4 sm:px-8 py-8">
                <div className="bg-white shadow-md rounded my-6">
                    <div className="px-6 py-4">
                        <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
                        <p className="text-gray-700 text-base">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
                        </p>
                    </div>
                    <div className="px-6 pt-4 pb-2">
                        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
                        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
                        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Blogpost;