import React from "react";

import plj_logo from "../assets/img/logoo.png";

const Footer = () => {
    return (
        <footer className="bg-[#343a40] text-[#a0aec0] relative">
                <div className="min-h-[20px] absolute inset-x-0 top-0 -mt-px w-full rotate-180">
                    <svg className="w-full h-5 sm:h-20 fill-white" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 2560 100" style={{ enableBackground: 'new 0 0 2560 100' }} xmlSpace="preserve">
                        <polygon points="2560 0 2560 100 0 100" />
                    </svg>
                </div>
                <div className="max-w-3xl lg:max-w-6xl mx-auto px-4 lg:px-2 pt-10 sm:pt-20">
                    <div className="lg:flex items-end justify-between pb-14">
                        <div>
                            <h3 className="font-medium text-3xl text-[#6c757d] pb-2">مواكبة أخبار المنصة؟ </h3>
                            <p className="text-white/80 text-xl">كن على اطلاع في الوقت المناسب !</p>
                        </div>
                        <form id="newsletter" method="POST" action="/#" className="flex items-center text-brand-black pt-12 lg:pt-0">
                            <input type="email" className="w-full lg:w-96 bg-white rounded focus:outline-none py-1.5 px-3" placeholder="...سجل بريدك الإلكتروني" />
                            <button type="submit" className="bg-[#f8f9fa] rounded hover:opacity-75 transition ease-linear py-1.5 px-3 mr-2">إرسال</button>
                        </form>
                    </div>
                    <hr className="border-0 h-px ml-28 md:ml-60" />
                    <div className="flex flex-wrap items-start justify-between py-10">
                        <div className="lg:max-w-xs pb-10 md:pb-0">
                            <img className="mx-auto w-10 h-10" src={plj_logo} alt="" />
                            <p className="text-sm opacity-80 text-center leading-6">
                            منصة الشباب فضاء لمواكبة الشباب لتحقيق مشاريعهم الشخصية والمهنية من خلال ورشات عمل تهدف لتسهيل الولوج لفرص العمل ودعم ريادة الأعمال و تعزيز الاقتصاد الاجتماعي والتضامني
                            </p>
                            <div className="text-center text-white pt-6">
                                
                            </div>
                        </div>
                        <div className="w-full md:w-auto py-4 md:py-0">
                            <h6 className="font-medium text-right sm:text-center pb-4">من نحن</h6>
                            <a className="text-white block text-right sm:text-center hover:underline" href="#">الاطار المؤسساتي</a>
                            <a className="text-white block text-right sm:text-center hover:underline" href="etapesintegration/">كيفية الانخراط</a>
                        </div>
                        <div className="w-full md:w-auto py-4 md:py-0">
                            <h6 className="font-medium text-right sm:text-center pb-4">الالتحاق بنا</h6>
                            <a className="text-white block text-right sm:text-center hover:underline" href="http://app.plj-laayoune.ma/">تسجيل المستفيدين</a>
                            <a className="text-white block text-right sm:text-center hover:underline" href="/programmes/">البرامج </a>
                        </div>
                        <div className="w-full md:w-auto py-4 md:py-0">
                            <h6 className="font-medium text-right sm:text-center pb-4">الموارد</h6>
                            <a className="text-white block text-right sm:text-center hover:underline" href="/contact/">تواصل معنا</a>
                            <a className="text-white block text-right sm:text-center hover:underline" href="/mentions-legales#/">إشعار قانوني</a>
                        </div>
                    </div>
                    <hr className="border-0 h-px ml-28 md:ml-60" />
                    <div className="flex flex-col md:flex-row items-center justify-center md:justify-between space-y-5 md:space-y-0 py-6">
                        <div className="text-sm font-bold text-center md:text-left">© 2023.
                            <span className="text-white">المبادرة الوطنية للتنمية البشرية</span>
                        </div>
                        
                    </div>
                </div>
            </footer>
    );
    }

    export default Footer;

