import React from "react";
import HeaderBG from "../assets/img/DSC_0298-2.jpg";
import plj_logo from "../assets/img/logoo.png";

function HeaderPages( props ) {




    return (
        <div className="relative bg-cover bg-no-repeat bg-center" style={{backgroundImage: `url(${HeaderBG})`}}>
        <div className="bg-[#1A96B9]/70 lg:hero">
          <header>
            <div className="max-w-6xl mx-auto flex items-center justify-between px-4 sm:px-8 py-3">
              <button className="block lg:hidden border border-white/25 rounded px-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-8 h-8 text-white/50">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </button>
              <div>
              <a className="flex items-center" href="/">
                  <img className="w-20 h-19 pl-6" src={plj_logo} alt="Plateforme des jeunes de Temara" />
                  <span className="uppercase text-sm font-bold text-white">منصة الشباب <br /> <span className="bg-brand-yellow text-brand-black rounded text-[10px] py-0.5 px-1">العيون</span></span>
                </a>
              </div>
              <div className="hidden lg:block text-white">
                <a className="hover:opacity-75 transition ease-linear p-2" href="/programmes/">البرامج </a>
                <a className="hover:opacity-75 transition ease-linear p-2" href="/etapesintegration/">مراحل
                  الانخراط</a>
                <a className="hover:opacity-75 transition ease-linear p-2" href="/contact/">تواصل معنا</a>
{/*                 <input className="border border-white rounded hover:bg-white hover:text-brand-dark-blue transition ease-linear cursor-pointer py-1.5 px-3 mr-3" type="submit" defaultValue="fr" />
 */}                <a className="bg-brand-yellow rounded hover:bg-brand-dark-yellow transition ease-linear mr-6 py-2.5 px-3" href="/#">تسجيل الدخول</a>
              </div>
            </div>
          </header>
          {/* hero */}
          <section className="max-w-6xl mx-auto px-4 sm:px-8 pt-16 pb-14 sm:pb-20 lg:pb-28">
            <div>
              <h1 className="font-light text-white text-40 pb-2">{props.title}</h1>
              <div className="w-20 h-px bg-brand-dark-yellow" />
            </div>
            <p className="font-light text-xl text-white leading-8 pt-4">{props.description}</p>

          </section>
{/*           <div className="min-h-[20px] absolute inset-x-0 bottom-0 sm:-mb-px w-full">
            <svg className="max-w-full w-full h-5 sm:h-full fill-white" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 2560 100" style={{enableBackground: 'new 0 0 2560 100'}} xmlSpace="preserve">
              <polygon points="2560 0 2560 100 0 100" />
            </svg>
          </div> */}
        </div>
        </div>  
        
    );
}
export default HeaderPages;