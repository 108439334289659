import React from "react";
import Footer from "../components/footer";
import HeaderPages from "../components/headerpages";


import loc2 from "../assets/img/gallery/imgf12.JPG";
import loc3 from "../assets/img/gallery/imgf17.JPG";
import loc4 from "../assets/img/gallery/imgf16.JPG";




export default function EtapesIntegration() {
    return (
        <div>
            <HeaderPages 
            title="مراحل التسجيل"
            description = "البرامج المدرجة موجهة للشباب من جميع الخلفيات، بغض النظر عن المستوى الأكاديمي، الاقتصادي"
            />



        <div className="min-h-[20px] absolute inset-x-0 bottom-0 sm:-mb-px w-full">
          <svg className="max-w-full w-full h-5 sm:h-full fill-white" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 2560 100" style={{enableBackground: 'new 0 0 2560 100'}} xmlSpace="preserve">
            <polygon points="2560 0 2560 100 0 100" />
          </svg>
        </div>
        <section className="max-w-6xl mx-auto px-4 py-16">
          <h2 className="font-medium text-[32px] text-center">عملية الانخراط في 3 مراحل !</h2>
          {/* 1 */}
          <div>
            <h1 className="text-white text-9xl font-black font-montserrat text-center leading-none text-shadow py-3">1</h1>
            <h3 className="font-extralight text-xl text-center pb-6">إنشاء حساب</h3>
            <hr className="border-0 h-px ml-28 md:ml-60" />
            <div className="flex flex-col lg:flex-row items-center pt-5">
              <div className="lg:w-1/2">
                <img className="w-[515px] lg:w-[333px] mx-auto  px-7 lg:px-0" src={loc2} alt="" />
              </div>
              <div className="lg:w-1/2">
                <p className="pb-4 leading-7">بمجرد  إنهاء تسجيلك يمكنك حجز موعد مع أحد مستشاري المنصة وفريق المواكبة. </p>
                <a href="https://app.plj-laayoune.ma" className="inline-block bg-brand-yellow hover:bg-brand-dark-yellow transition ease-linear rounded m-2 py-2 px-3">جدول
                  المواعيد المبرمجة</a>
              </div>
            </div>
          </div>
          {/* 2 */}
          <div>
            <h1 className="text-white text-9xl font-black font-montserrat text-center leading-none text-shadow py-3">2</h1>
            <h3 className="font-extralight text-xl text-center pb-6">الحضور للقائنا</h3>
            <hr className="border-0 h-px ml-28 md:ml-60" />
            <div className="flex flex-col lg:flex-row items-center pt-5">
              <div className="lg:w-1/2">
                <p className="pb-4 leading-7">للوصول إلى اللقاءات  المجدولة التالية ، ألق نظرة علىحسابك أو انقر على الرابط أدناه</p>
                <p className="pb-4 leading-7">خلال هذه الجلسات ، ستتاح لك الفرصة لطرح جميع أسئلتكعلى الفريق في الموقع وابدأ رحلة تحديد
                  المواقع الخاصة بك.</p>
                <a href="https://app.plj-laayoune.ma" className="inline-block bg-brand-yellow hover:bg-brand-dark-yellow transition ease-linear rounded m-2 py-2 px-3">جدول
                  المواعيد المبرمجة</a>
              </div>
              <div className="lg:w-1/2">
                <img className="w-[515px] lg:w-[405px] mx-auto  rounded px-7 lg:px-0" src={loc3} alt="" />
              </div>
            </div>
          </div>
          {/* 3 */}
          <div>
            <h1 className="text-white text-9xl font-black font-montserrat text-center leading-none text-shadow py-3">3</h1>
            <h3 className="font-extralight text-xl text-center pb-6">الحضور للقائنا</h3>
            <hr className="border-0 h-px ml-28 md:ml-60" />
            <div className="flex flex-col lg:flex-row items-center pt-5">
              <div className="lg:w-1/2">
                <img className="w-[515px] lg:w-[333px] mx-auto  px-7 lg:px-0" src={loc4} alt="" />
              </div>
              <div className="lg:w-1/2">
                <p className="pb-4 leading-7">
                بعد الحضور للقاء مع المستشارين يتم توجيهك و إحالتك على البرنامج المناسب لطلبك . 
                </p>
           </div>
            </div>
          </div>

          {/* 4 cards section */}

{/*           <div className="grid sm:grid-1 justify-center space-x-4">
          
        <div className="block rounded-lg shadow-lg bg-white max-w-[20%] text-center">
          
          <div className="p-6">
            <h5 className="text-gray-900 text-xl font-medium mb-2">التكوين والمواكبة</h5>
            <p className="text-gray-700 text-base mb-4">
            الرفع من فرص الإدماج الاقتصادي للشباب من خلال برامج وأنشطة لتطوير كفاءاتهم و مهاراتهم.            </p>
          </div>
          
        </div>
        <div className="block rounded-lg shadow-lg bg-white  max-w-[20%] text-center">
          
          <div className="p-6">
            <h5 className="text-gray-900 text-xl font-medium mb-2">الاقتصاد الاجتماعي والتضامني</h5>
            <p className="text-gray-700 text-base mb-4">
            تحديد ودعم سلاسل الإنتاج الواعدة ذات القيمة المضافة للاقتصاد المحلي
            </p>
          </div>
          
        </div>
        <div className="block rounded-lg shadow-lg bg-white  max-w-[20%] text-center">
         
          <div className="p-6">
            <h5 className="text-gray-900 text-xl font-medium mb-2">تحسين قابلية التشغيل</h5>
            <p className="text-gray-700 text-base mb-4">
            أنشطة وورشات من أجل تلقين الشباب مهارات ضرورية لضمان ولوج يسير لسوق الشغل
            </p>
          </div>
          
        </div>
        
        <div className="block rounded-lg shadow-lg bg-white  max-w-[20%] text-center">
          
          <div className="p-6">
            <h5 className="text-gray-900 text-xl font-medium mb-2">ريادة الأعمال</h5>
            <p className="text-gray-700 text-base mb-4">
            دعم الحس المقاولاتي لدى الشباب وتمكينهم من آليات إحداث المقاولة وطرق تمويلها وسبل استدامتها            </p>
          </div>
          
        </div>
      </div>
 */}

         
<div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-5 text-center">
{/*Card 1*/}
        <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm mb-5">
                        
                        <div className="p-5">
                       
                            <h5 className="text-gray-900 font-bold text-2xl tracking-tight mb-2">
                            ريادة الأعمال                            </h5>
                        
                        <p className="font-normal text-gray-700 mb-3">
                        دعم الحس المقاولاتي لدى الشباب وتمكينهم من آليات إحداث المقاولة وطرق تمويلها وسبل استدامتها                     </p>
                        
                        </div>
        </div>
        <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm mb-5">
                        
                        <div className="p-5">
                        
                            <h5 className="text-gray-900 font-bold text-2xl tracking-tight mb-2">
                            تحسين قابلية التشغيل 
                            </h5>
                       
                        <p className="font-normal text-gray-700 mb-3 ">
                        أنشطة وورشات من أجل تلقين الشباب مهارات ضرورية لضمان ولوج يسير لسوق الشغل</p>
                        
                        </div>
        </div>
        <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm mb-5">
                       
                        <div className="p-5">
                        
                            <h5 className="text-gray-900 font-bold text-2xl tracking-tight mb-2">
                            الاقتصاد الاجتماعي والتضامني  
                       </h5>
                        
                        <p className="font-normal text-gray-700 mb-3">
                        تحديد ودعم سلاسل الإنتاج الواعدة ذات القيمة المضافة للاقتصاد المحلي
                        </p>
                        
                        </div>
        </div>


        <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm mb-5">
                       
                        <div className="p-5">
                        
                            <h5 className="text-gray-900 font-bold text-2xl tracking-tight mb-2">
                            التكوين والمواكبة                       </h5>
                        
                        <p className="font-normal text-gray-700 mb-3">
                        الرفع من فرص الإدماج الاقتصادي للشباب من خلال برامج وأنشطة لتطوير كفاءاتهم و مهاراتهم.
                        </p>
                        
                        </div>
        </div>

        </div>


          <div className="flex flex-col md:flex-row items-center justify-center py-12">
            <a href="/#" className="flex items-center text-xl bg-brand-yellow rounded hover:bg-brand-dark-yellow transition ease-linear py-[9px] px-3 m-2">
              <span>إنشاء حساب</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                <line x1={12} y1={5} x2={12} y2={19} />
                <line x1={5} y1={12} x2={19} y2={12} />
              </svg>
            </a>
            <a href="/#" className="flex items-center text-xl text-brand-yellow border border-brand-yellow text-center rounded hover:bg-brand-yellow hover:text-brand-black transition ease-linear py-2 px-3 m-2">
              <span>الإطلاع على الأنشطة المبرمجة</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                <line x1={12} y1={5} x2={12} y2={19} />
                <line x1={5} y1={12} x2={19} y2={12} />
              </svg>
            </a>
          </div>
        </section>

        
            <Footer />
        </div>
    );
    }
