import React from "react";
import Header from "../components/header";
import FAQ from "../components/faq";
import Footer from "../components/footer";
import Articles from "../components/articles";

import cbox from "../assets/img/07.jpg";


import loc2 from "../assets/img/v.png";
import loc4 from "../assets/img/11.jpg";


import client0 from "../assets/img/com-l.png";
import client1 from "../assets/img/logo_indhl.png";
import client2 from "../assets/img/assos.png";
import client4 from "../assets/img/indh.png";
import client5 from "../assets/img/dar-m.png";

import img_formation from "../assets/img/10.jpg";
import img_formation2 from "../assets/img/08.jpg";
import img_formation3 from "../assets/img/04.jpg";

/* import employability from "../assets/img/employability.svg";
import entrepreneurship from "../assets/img/entrepreneurship.svg";
import listening from "../assets/img/listening.svg"; */


export default function Home() {
    return (
        <div>
            <Header />


            <section className="max-w-xl md:max-w-6xl mx-auto px-4 md:px-1.5 pt-32 lg:pt-20">
          <div className="flex justify-center pb-1">
            <p className="inline bg-brand-yellow font-bold text-brand-black rounded text-xs py-0.5 px-1">خطاب العرش </p>
          </div>
          <h3 className="text-40 text-center pb-6">مقتطف من خطاب العرش بتاريخ 29يوليوز2018</h3>
          <div>
           
            
            <hr className="border-0 h-px ml-28 md:ml-60" />
            <div className="flex flex-col lg:flex-row items-center pt-5">
              <div className="lg:w-1/2">
                <img className="w-[515px] lg:w-[503px] mx-auto  px-7 lg:px-0  rounded-md" src={loc2} alt="" />
              </div>
              <div className="lg:w-1/2">
                <p className="pb-4 leading-7">ترأس صاحب الجلالة الملك محمد السادس، نصره الله، يوم الأربعاء 19 شتنبر 2018 بالقصر الملكي بالرباط، حفل إطلاق المرحلة الثالثة من المبادرة الوطنية للتنمية البشرية (2019-2023)، التي تمت بلورتها وفق هندسة جديدة، وتتطلب تعبئة استثمارات تقدر بـ 18 مليار درهم. وتهدف المرحلة الثالثة من المبادرة الوطنية للتنمية البشرية إلى تحصين مكتسبات المرحلتين السابقتين، من خلال إعادة تركيز برامج المبادرة على النهوض بالرأسمال البشري، والعناية بالأجيال الصاعدة، ودعم الفئات في وضعية هشاشة، وذلك اعتمادا على منهجية مبنية على حكامة خلاقة ومبدعة ترمي إلى تحقيق مزيد من الانسجام والفعالية.
                </p>
                <a href="https://app.plj-laayoune.ma" className="inline-block bg-brand-yellow hover:bg-brand-dark-yellow transition ease-linear rounded m-2 py-2 px-3">جدول
                  المواعيد المبرمجة</a>
              </div>
            </div>
          </div>
        </section>
        {/* taarif */}      

        <section className="max-w-xl md:max-w-6xl mx-auto px-4 md:px-1.5 pt-32 lg:pt-20 ">
          <div className="flex justify-center pb-1">
            <p className="inline bg-brand-yellow font-bold text-brand-black rounded text-xs py-0.5 px-1">التأسيس</p>
          </div>
          <h3 className="text-40 text-center pb-6">تعريف منصة الشباب</h3>
          <div>
           
            
            <hr className="border-0 h-px ml-28 md:ml-60" />
            <div className="flex flex-col lg:flex-row items-center pt-5">
              
              <div className="lg:w-1/2">
                <p className="pb-4 leading-7">
                أحدثت منصة الشباب بإقليم العيون في إطار التنزيل الفعلي للمرحلة الثالثة من المبادرة الوطنية للتنمية البشرية (2019-2023) خاصة البرنامج الثالث المتعلق بتحسين الدخل والادماج الاقتصادي للشباب.
وتم افتتاحها من طرف وفد رسمي ترأسه السيد والي جهة العيون الساقية الحمراء بتاريخ 25 ينايـر 2021 .
                </p>
                <a href="https://app.plj-laayoune.ma" className="inline-block bg-brand-yellow hover:bg-brand-dark-yellow transition ease-linear rounded m-2 py-2 px-3">جدول
                  المواعيد المبرمجة</a>
              </div>
              <div className="lg:w-1/2">
                <img className="w-[515px] lg:w-[503px] mx-auto  px-7 lg:px-0  rounded-md" src={loc4} alt="" />
              </div>
            </div>
          </div>
        </section>


        {/* idmaj */}

        <section className="max-w-xl md:max-w-6xl mx-auto px-4 md:px-1.5 pt-32 lg:pt-20 ">
          <div className="flex justify-center pb-1">
            <p className="inline bg-brand-yellow font-bold text-brand-black rounded text-xs py-0.5 px-1">فضاءات المنصة </p>
          </div>
          <h3 className="text-40 text-center pb-6">
          فضاءات المنصة         
           </h3>
          <p className="max-w-3xl mx-auto font-light text-xl opacity-80 text-center md:px-4 pb-28">

          </p>
          <div>
           
            
            <hr className="border-0 h-px ml-28 md:ml-60" />
            <div className="flex flex-col lg:flex-row items-center pt-5">
            <div className="lg:w-1/2">
                <img className="w-[515px] lg:w-[503px] mx-auto  px-7 lg:px-0  rounded-md" src={img_formation} alt="" />
              </div>
              <div className="lg:w-1/2">
                <h3 className="text-30  pb-6">فضاء الاستقبال والاستماع</h3>
                <hr className="border-3 w-20" />
                <p className="pb-4 leading-7">
                استقبال الشباب والاستماع لهم من طرف متخصصين قصد تلبية احتياجاتهم فيما يخص إيجاد فرص الشغل وأليات إحداث المقاولة.                  </p>
                <a href="https://app.plj-laayoune.ma" className="inline-block bg-brand-yellow hover:bg-brand-dark-yellow transition ease-linear rounded m-2 py-2 px-3">جدول
                  المواعيد المبرمجة</a>
              </div>
              
            </div>
          </div>
        </section>

        {/* val ajouter */} 
        <section className="max-w-xl md:max-w-6xl mx-auto px-4 md:px-1.5 pt-32 lg:pt-20 ">
          
          
          <div>
           
            
            <hr className="border-0 h-px ml-28 md:ml-60" />
            <div className="flex flex-col lg:flex-row items-center pt-5">
            
              <div className="lg:w-1/2">
                <h3 className="text-30  pb-6">فضاء التوجيه</h3>
                <hr className="border-3 w-20" />
                <p className="pb-4 leading-7">
                يخصص هذا الفضاء لتوجيه الشباب نحو فرص التكوين الملائمة لتطلعاتهم ومؤهلاتهم.                             
</p>
                {/* add a list */} 
                {/* <ul className="list-disc list-inside">
                  <li>تشجيع مقاربة سلاسل القيم</li>
                  <li>تيسير الالتقائية عبر إطار للحكامة على المستوى الترابي</li>
                  <li>تدبير بنك للمشاريع لتسهيل توزيع الدعم المخصص لها</li>
                </ul> */}

                <a href="https://app.plj-laayoune.ma" className="inline-block bg-brand-yellow hover:bg-brand-dark-yellow transition ease-linear rounded m-2 py-2 px-3">جدول
                  المواعيد المبرمجة</a>
              </div>
              <div className="lg:w-1/2">
                <img className="w-[515px] lg:w-[503px] mx-auto  px-7 lg:px-0  rounded-md" src={img_formation2} alt="" />
              </div>
              
            </div>
          </div>
        </section>

        {/* istidama pme */}
        <section className="max-w-xl md:max-w-6xl mx-auto px-4 md:px-1.5 pt-32 lg:pt-20 ">
          
          
          <div>
           
            
            <hr className="border-0 h-px ml-28 md:ml-60" />
            <div className="flex flex-col lg:flex-row items-center pt-5">
            <div className="lg:w-1/2">
                <img className="w-[515px] lg:w-[503px] mx-auto  px-7 lg:px-0  rounded-md" src={img_formation3} alt="" />
              </div>
              <div className="lg:w-1/2">
                <h3 className="text-30  pb-6">فضاء المواكبة</h3>
                <hr className="border-3 w-20" />
                <p className="pb-4 leading-7">
                يتكفل هذا الفضاء بمواكبة الشباب لتحقيق مشاريعهم الشخصية والمهنية من خلال ورشات عمل تهدف لتسهيل الولوج لفرص العمل ودعم ريادة الاعمال وتعزيز الاقتصاد الاجتماعي والتضامني.               </p>
              
                

                <a href="https://app.plj-laayoune.ma" className="inline-block bg-brand-yellow hover:bg-brand-dark-yellow transition ease-linear rounded m-2 py-2 px-3">جدول
                  المواعيد المبرمجة</a>
              </div>
              
              
            </div>
          </div>
        </section>
        


        <section className="bg-gradient-to-l from-[#3399cc] to-[#4285c5] mt-24">
          <div className="max-w-xl md:max-w-6xl mx-auto flex flex-col md:flex-row items-center px-4 lg:px-2 pt-24 pb-16">
            <div className="md:w-1/2 pt-10 md:pt-0">
              <p className="inline bg-brand-yellow font-bold text-brand-black rounded text-xs py-0.5 px-1">توجه المنصة</p>
              <h3 className="font-medium text-40 text-white pb-6">إتبع الخطوات</h3>
              <p className="font-light text-white text-xl pb-16">قم بإنشاء حسابك الخاص و تعال للقائنا</p>
              <div className="flex flex-col lg:flex-row items-start lg:items-center">
                <a href="/#" className="flex items-center bg-white rounded hover:opacity-75 transition ease-linear py-1.5 px-3 m-2">
                  <span>إنشاء حساب</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                    <line x1={12} y1={5} x2={12} y2={19} />
                    <line x1={5} y1={12} x2={19} y2={12} />
                  </svg>
                </a>
                <a href="/#" className="flex items-center text-white border border-white rounded hover:bg-white hover:text-brand-black transition ease-linear py-1.5 px-3 m-2">
                  <span>الإطلاع على مراحل الانخراط</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                    <line x1={12} y1={5} x2={12} y2={19} />
                    <line x1={5} y1={12} x2={19} y2={12} />
                  </svg>
                </a>
              </div>
            </div>
            <div className="md:w-1/2 order-first md:order-last px-10 sm:px-0 " >
              <img className="w-96 md:w-60 lg:w-[600px] mx-auto  rounded-md" src={cbox} alt="" />
            </div>
          </div>
        </section>


        <section className="max-w-xl md:max-w-6xl mx-auto px-4 md:px-1.5 pt-32 lg:pt-20 ">
          <div className="flex justify-center pb-1">
            <p className="inline bg-brand-yellow font-bold text-brand-black rounded text-xs py-0.5 px-1">خدمات المنصة </p>
          </div>
          <h3 className="text-40 text-center pb-6">خدمات المنصة</h3>

          
{/* •	توفير فضاء يستجيب لاحتياجات الشباب المتعلقة بتحسين الدخل والادماج الاقتصادي;
•	استقبال الشباب الباحث عن عمل أو الحامل لأفكار مشاريع;
•	الاستماع للشباب وتوجيههم نحو الفرص الملائمة لتطلعاتهم ومؤهلاتهم;
•	دعم وتأطير ومواكبة حاملي أفكار المشاريع المبتكرة والقابلة للتنفيذ في مجال ريادة الأعمال;
•	تسهيل إدماج الشباب في سوق الشغل المحلي عبر تأهيله لمسايرة متطلبات هذه السوق;
•	المساعدة على خلق التعاونيات وإنشاء المقاولات وبلورة الإطار القانوني للمشاريع المدرة للدخل حسب طبيعة نشاطها;
•	دعم الهيئات العاملة في مجال الاقتصاد التضامني;
•	توفير مساحة عمل مشتركة تمكن الشباب من الوصول للمعلومة المتعلقة بالبحث عن عمل أو فرص التمويل المتاحة بالإقليم.
 */}
 <ul className="list-disc list-inside">
                  
                 
                  
                </ul>
              
          

                <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5 text-center">
{/*Card 1*/}
        <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm mb-5">
                        
                        <div className="p-5">
                       
                          
                        
                        <ul className="font-normal text-gray-700 mb-3">
                        <li>دعم وتأطير ومواكبة حاملي أفكار المشاريع المبتكرة والقابلة للتنفيذ في مجال ريادة الأعمال;</li>
                        <br></br>
                  <li>تسهيل إدماج الشباب في سوق الشغل المحلي عبر تأهيله لمسايرة متطلبات هذه السوق;</li>
                  <br></br>
                  <li>المساعدة على خلق التعاونيات وإنشاء المقاولات وبلورة الإطار القانوني للمشاريع المدرة للدخل حسب طبيعة نشاطها;</li>
                  <br></br>
                        </ul>
                        
                        </div>
        </div>
        <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm mb-5">
                        
                        <div className="p-5">
                        
                        <ul className="font-normal text-gray-700 mb-3">
                        <li>توفير فضاء يستجيب لاحتياجات الشباب المتعلقة بتحسين الدخل والادماج الاقتصادي;</li>
                        <br></br>
                  <li>استقبال الشباب الباحث عن عمل أو الحامل لأفكار مشاريع;</li>
                  <br></br>
                  <li>الاستماع للشباب وتوجيههم نحو الفرص الملائمة لتطلعاتهم ومؤهلاتهم;</li>    
                  <br></br>                    </ul>
                       
                        
                        </div>
        </div>
        <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm mb-5">
                       
                        <div className="p-5">
                        
                        <ul className="font-normal text-gray-700 mb-3">
                        <li>دعم الهيئات العاملة في مجال الاقتصاد التضامني;</li>
                        <br></br>
                  <li>توفير مساحة عمل مشتركة تمكن الشباب من الوصول للمعلومة المتعلقة بالبحث عن عمل أو فرص التمويل المتاحة بالإقليم.</li>
                        </ul>
                        
                        </div>
        </div>


       

        </div>
        </section>



        {/* akhbar */}
        <section className="max-w-xl md:max-w-6xl mx-auto px-4 md:px-1.5 pt-32 lg:pt-20 ">
          <div className="flex justify-center pb-1">
          </div>
          <h3 className="text-40 text-center pb-6">اخبار المنصة</h3>
          <div>
           
            
            <hr className="border-0 h-px ml-28 md:ml-60" />
            <Articles />
          </div>

         
        </section>

        {/* partenaires */}
        <section className="max-w-xl md:max-w-6xl mx-auto px-4 md:px-1.5 pt-32 lg:pt-20 ">
          <div className="flex justify-center pb-1">
            <p className="inline bg-brand-yellow font-bold text-brand-black rounded text-xs py-0.5 px-1">شركاؤنا </p>
          </div>
          <h3 className="text-40 text-center pb-6">شركاؤنا</h3>
          <div>
           
            
            <hr className="border-0 h-px ml-28 md:ml-60" />
            <Slider />
          </div>

        </section>

      
        {/* faq */}
        <FAQ />

        <Footer />

            
        </div>
    );
    }



    function Slider () {
      return (
          <div>
          <div className="container mx-auto">
              <div className="flex flex-wrap justify-center">
                  
                  <div className="w-1/12 p-2 pt-10">
                      <img src={client2} alt />
                  </div>
                  <div className="w-3/12 pt-10 ">
                      <img src={client4} alt />
                  </div>
                  <div className="w-2/12  pt-10">
                      <img src={client5} alt />
                  </div>
                 
                  
              </div>
          </div>
      </div>
      )
  }