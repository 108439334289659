
import React from "react";


import SliderHome from "../components/slider_home";
import Header from "../components/header";
import Footer from "../components/footer";
import HeaderPages from "../components/headerpages";
import Carousell from "../components/carousell";

import client0 from "../assets/img/com-l.png";

function Newhome() {
    return (
        <div>
            
            <HeaderPages title="Programmes" description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatem." />
          {/*   <SliderHome /> */}
          {/* <Slider /> */}

          <div className="container mx-auto">
        
          <Carousell />
            </div>  
            <Footer/>
        </div>
    )
}



  




export default Newhome  ;


function Slider () {
    return (
        <div>
        <div className="container mx-auto">
            <div className="flex flex-wrap justify-center">
                <div className="w-1/12 p-2">
                    <img src={client0} alt />
                </div>
                <div className="w-1/12 p-2">
                    <img src={client0} alt />
                </div>
                <div className="w-1/12 p-2">
                    <img src={client0} alt />
                </div>
                <div className="w-1/12 p-2">
                    <img src={client0} alt />
                </div>
                <div className="w-1/12 p-2">
                    <img src={client0} alt />
                </div>
                
            </div>
        </div>
    </div>
    )
}