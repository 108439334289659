import React from "react";
import home_header_img from "../assets/img/11.jpg";
import plj_logo from "../assets/img/logoo.png";

export default function  Header() {
    return (
        <div>
        <div className="relative bg-cover bg-no-repeat bg-center" style={{backgroundImage: `url(${home_header_img})`}}>
        <div className="bg-[#1b3f7d]/80">
          <header className="relative">
            <div className="max-w-6xl mx-auto flex items-center justify-between px-4 sm:px-8 py-3">
              <button className="menuBtn block lg:hidden border border-white/25 rounded px-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-8 h-8 text-white/50">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </button>
              <div>
                <a className="flex items-center" href="http://app.plj-laayoune.ma/">
                  <img className="w-20 h-19 pl-6" src={plj_logo} alt="Plateforme des jeunes de Temara" />
                  <span className="uppercase text-sm font-bold text-white">منصة الشباب <br /> <span className="bg-brand-yellow text-brand-black rounded text-[10px] py-0.5 px-1">العيون</span></span>
                </a>
              </div>
              <div className="hidden lg:block text-white">
                <a className="hover:opacity-75 transition ease-linear p-2" href="/programmes/">البرامج </a>
                <a className="hover:opacity-75 transition ease-linear p-2" href="/etapesintegration/">مراحل الانخراط</a>
                <a className="hover:opacity-75 transition ease-linear p-2" href="/contact/">تواصل معنا</a>
{/*                 <input className="border border-white rounded hover:bg-white hover:text-brand-dark-blue transition ease-linear cursor-pointer py-1.5 px-3 mr-3" type="submit" defaultValue="fr" />
 */}                <a className="bg-brand-yellow rounded hover:bg-brand-dark-yellow transition ease-linear mr-6 py-2.5 px-3" href="http://app.plj-laayoune.ma/">تسجيل الدخول</a>
              </div>

            </div>
            {/* mobile menu */}
            <div className="menu absolute top-28 inset-x-10 z-50 bg-[#343a40] rounded-xl text-white -translate-x-[150%] transition duration-300 ease-linear">
              <a className="block text-center p-2" href="/programmes/">البرامج </a>
              <a className="block text-center p-2" href="/etapesintegration/">مراحل الانخراط</a>
              <a className="block text-center p-2" href="/contact/">تواصل معنا</a>
              <div className="flex justify-center p-2">
                <input className="block mx-auto border border-white rounded hover:bg-white hover:text-brand-dark-blue transition ease-linear cursor-pointer py-1.5 px-3" type="submit" defaultValue="fr" />
              </div>
              <div className="flex justify-center p-2">
                <a className="inline-block bg-brand-yellow rounded hover:bg-brand-dark-yellow transition ease-linear py-2.5 px-3" href="http://app.plj-laayoune.ma/">تسجيل الدخول</a>
              </div>
            </div>
          </header>
          {/* hero */}
          <section className="max-w-6xl mx-auto px-4 sm:px-8 pt-24 lg:pt-32 pb-14 sm:pb-20 lg:pb-28">
            <div className="max-w-3xl ml-auto">
              <h1 className="font-light text-white text-6xl text-center lg:text-right pb-4">طور <span className="text-[#f39c12]">قدراتك المهنية</span></h1>
              <p className="font-light text-xl text-white text-center lg:text-right pb-12">التعلم.تسيير الأعمال، البحث عن عمل، مكان للعمل المشترك</p>
              <div className="flex justify-center lg:justify-start">
                <a href="http://app.plj-laayoune.ma/" className="inline-flex items-center text-xl text-white border border-white rounded hover:text-brand-dark-blue hover:bg-white transition ease-linear py-2 px-4">
                  <span>للتسجيل</span>
                  <span className="btn-inner--icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus">
                      <line x1={12} y1={5} x2={12} y2={19} />
                      <line x1={5} y1={12} x2={19} y2={12} />
                    </svg>
                  </span>
                </a>
              </div>
              
            </div>
          </section>
{/*           <div className="min-h-[20px] absolute inset-x-0 bottom-0 sm:-mb-px w-full">
            <svg className="max-w-full w-full h-5 sm:h-full fill-white" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 2560 100" style={{enableBackground: 'new 0 0 2560 100'}} xmlSpace="preserve">
              <polygon points="2560 0 2560 100 0 100" />
            </svg>
          </div> */}
        </div>
      </div>
        </div>
    );
}

