import React from "react";

import maplaayoune from "../assets/img/articles/map-laayoune.jpg";
import laayouneonline from "../assets/img/articles/laayoune-online.jpg";
import aswatnews from "../assets/img/articles/aswatnews.jpeg";

const Articles = () => {
    return (
    
       

<div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
{/*Card 1*/}
<div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm mb-5">
                <a href="#">
                <img
                    className="rounded-t-lg  w-[338px] h-[225.2px]"
                    src={aswatnews}
                    alt=""
                />
                </a>
                <div className="p-5">
                <a href="#">
                    <h5 className="text-gray-900 font-bold text-2xl tracking-tight mb-2">
                    والي جهة العيون يطلق ”منصة الشباب” بحي الدويرات بمدينة العيون
                    </h5>
                </a>
                <p className="font-normal text-gray-700 mb-3">
                أشرف والي جهة العيون الساقية الحمراء، ورئيس المجلس الجماعي للعيون ونائب رئيس الجهة ورئيس جماعة المرسى، اليوم الإثنين 25 يناير2021، على إفتتاح منصة الشباب .
                </p>
                <a
                    className="text-blacke bg-brand-yellow focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
                    href="#"
                >
                    اقرأ المزيد
                </a>
                </div>
</div>
<div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm mb-5">
                <a href="#">
                <img
                    className="rounded-t-lg w-[338px] h-[225.2px]" 
                    src={maplaayoune}
                    alt=""
                />
                </a>
                <div className="p-5">
                <a href="#">
                    <h5 className="text-gray-900 font-bold text-2xl tracking-tight mb-2">
                    إطلاق منصة الشباب بالعيون

                    </h5>
                </a>
                <p className="font-normal text-gray-700 mb-3 md:pt-[50px]">
                تم، اليوم الاثنين بالعيون، إطلاق منصة الشباب، بهدف دعم قابلية التشغيل لدى الشباب ومواكبتهم من أجل إحداث المشاريع.
                </p>
                <a
                    className="text-blacke bg-brand-yellow focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
                    href="#"
                >
                    اقرأ المزيد
                </a>
                </div>
</div>
<div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm mb-5">
                <a href="#">
                <img 
                    className="rounded-t-lg w-[338px] h-[225.2px]"
                    src={laayouneonline}
                    alt=""
                />
                </a>
                <div className="p-5">
                <a href="#">
                    <h5 className="text-gray-900 font-bold text-2xl tracking-tight mb-2">
                    افتتاح منصة الشباب بمدينة العيون
                    </h5>
                </a>
                <p className="font-normal text-gray-700 mb-3">
                افتتح عبد السلام بيكرات، والي جهة العيون الساقية الحمراء رفقة السلطة المحلية و رؤساء المجالس المنتخبة المحلية، صباح الإثنين، منصة الشباب بمدينة العيون.

                </p>
                <a
                    className="text-blacke bg-brand-yellow focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
                    href="#"
                >
                    اقرأ المزيد
                </a>
                </div>
</div>

</div>





    )
}

export default Articles