import React from "react";
import { useState } from "react";




function Faq() {

    const [clicked, setClicked] = useState("0");    


    return (
        <div>
        <section className="max-w-6xl mx-auto px-4 lg:px-2 pt-12 pb-24">
          <h3 className="text-3xl text-center pb-6">اسئلة متداولة</h3>
          <div className="lg:flex items-start leading-7 space-y-6 lg:space-y-0">
            <div className="lg:w-1/2 space-y-6 lg:ml-4">
              <div className="border border-brand-gray rounded-xl overflow-hidden">
                <div className="flex justify-between p-6">
                  <h2 className="h6 mb-0">هل الإنخراط بالفعل مجاني؟</h2>
                  <span onClick={() => setClicked("1")}>
                    {clicked === "1" ? (
                        

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path d="M10.75 6.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" />
                              </svg> )}
                    </span>
                    
                    

                </div>
                <div hidden={clicked === "1" ? false : true}>
                  <div className="text-brand-slate border-t border-brand-gray p-7">
                  ملتقى الشباب مجاني ، فقط يجب عليك التسجيل في دورات التواصل المبرمجة و إتباع الخطوات من أجل لقائنا
                  </div>
                </div>
              </div>
              <div className="border border-brand-gray rounded-xl overflow-hidden">
                <div className="flex justify-between p-6">
                  <h2 className="h6 mb-0">في حالة كنت أبحث عن عمل،كيف بإمكان هذه المنصة أن تساعدني؟</h2>
                  <span onClick={() => setClicked("2")}>
                    {clicked === "2" ? (
                        

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path d="M10.75 6.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" />
                              </svg> )}
                    </span>
                </div>
                <div  hidden={clicked === "2" ? false : true}>
                  <div className="text-brand-slate border-t border-brand-gray p-7">
                  تتوفر أنشطة مختلفة مبرمجة  و يمكن أن تتم برمجتها لهذف مساعدتك لإيجاد فرصتك أو الطريق الملائم لك، لا 

تتردد في الحضور للورشات المفتوحة للجميع لأجل التعرف على المنهجية المعتمدة و خطة العمل التي يمكنها مساعدتك

                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 space-y-6 lg:mr-4">
              <div className="border border-brand-gray rounded-xl overflow-hidden">
                <div className="flex justify-between p-6">
                  <h2 className="h6 mb-0">من هم الفئة التي تستهدف من أجل الأنشطة المبرمجة؟</h2>
                  <span onClick={() => setClicked("3")}>
                    {clicked === "3" ? (
                        

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path d="M10.75 6.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" />
                              </svg> )}
                    </span>
                </div>
                <div  hidden={clicked === "3" ? false : true}>
                  <div className="text-brand-slate border-t border-brand-gray p-7">
                  إذا كنت شابا سنك ما بين 18 إلى 35  فإن هذه البرامج تهمك و مخصصة لك.                  </div>
                </div>
              </div>
              <div className="border border-brand-gray rounded-xl overflow-hidden">
                <div className="flex justify-between p-6">
                  <h2 className="h6 mb-0">أريد القيام بنشاط حر في إطار مشروع شخصي، كيف ستقوم المنصة بمواكبتي ؟</h2>
                  <span onClick={() => setClicked("4")}>
                    {clicked === "4" ? (
                        

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path d="M10.75 6.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" />
                              </svg> )}
                    </span>
                </div>
                <div  hidden={clicked === "4" ? false : true}>
                  <div className="text-brand-slate border-t border-brand-gray p-7">
                  في منصة الشباب توجد برامج للباحثين عن عمل و أصحاب المشاريع في نفس الوقت، سواء إن كنت تتوفر على فكرة أم لا، سيتم الإنصات لك، توجيهك،مواكبتك ثم متابعتك بقدر طموحك. لا تتردد في التسجيل للإستفادة 

من ورشات عملية و تفاعلية

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
    );
    }

    export default Faq;