import React from "react";
import Footer from "../components/footer";
import HeaderPages from "../components/headerpages";

export default function Contact() {
    return (
        <div>
        <HeaderPages
        title="للتواصل معنا أو تحديد موقع المنصة"
        description="للتواصل معنا أو تحديد موقع المنصة"
        />


        <section className="max-w-6xl mx-auto lg:flex items-center pt-10 pb-20 px-4">
          <div className="lg:w-1/2">
            <h3 className="font-medium text-3xl mb-2">
                            منصة الشباب العيون
</h3>
            <div className="pt-2 sm:flex space-y-2 sm:space-y-0">
              
              <a target="_blank" className="inline-block text-brand-blue-2 border border-brand-blue-2 rounded hover:bg-brand-blue-2 hover:text-white transition ease-linear py-1.5 px-3 mx-1" href="https://goo.gl/maps/S36Y9T9q7nw8xCTD6">Ouvrir sur Google Maps</a>
              
            </div>
            <p className="pt-6 pb-12">
              <i className="fas fa-at" aria-hidden="true" />
              <span className="font-light sm:text-xl">plateforjeunes@gmail.com</span>
            </p>
            <hr className="border-0 h-px ml-28 md:ml-60" />
            <p className="py-12">
              <i className="fas fa-phone-volume" aria-hidden="true" />
              <span className>+212 5289-92828</span>
            </p>
            <hr className="border-0 h-px ml-28 md:ml-60" />
            <p className="py-12">
              <i className="fas fa-bus" aria-hidden="true" />
              <span className>
              تجزئة الوحدة بلوك D زنقة ايت موسى وأعلي
              </span>
            </p>
            <hr className="border-0 h-px ml-28 md:ml-60" />
            <p className="py-12">
              <i className="fab fa-facebook" aria-hidden="true" />
              <a href="https://www.facebook.com/" traget="_blank" className="text-brand-blue-2 hover:text-brand-blue">
              منصة الشباب العيون
              </a>
            </p>
          </div>
          <div className="lg:w-1/2">
            {/* <div id="map" className="map-canvas rounded-left mapboxgl-map" style={{height: '600px', position: 'relative', overflow: 'hidden'}}>
              <div className="mapboxgl-canary" style={{visibility: 'hidden'}} />
              <div className="mapboxgl-canvas-container mapboxgl-interactive mapboxgl-touch-drag-pan mapboxgl-touch-zoom-rotate"><canvas className="mapboxgl-canvas" tabIndex={0} aria-label="Map" width={540} height={600} style={{width: '540px', height: '600px'}} />
                <div aria-label="Map marker" className="mapboxgl-marker mapboxgl-marker-anchor-center" style={{transform: 'translate(-50%, -50%) translate(219px, 212px) rotateX(0deg) rotateZ(0deg)'}}><svg display="block" height="41px" width="27px" viewBox="0 0 27 41">
                    <g fillRule="nonzero">
                      <g transform="translate(3.0, 29.0)" fill="#000000">
                        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273" />
                        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273" />
                        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="9.5" ry="4.77275007" />
                        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="8.5" ry="4.29549936" />
                        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="7.5" ry="3.81822308" />
                        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="6.5" ry="3.34094679" />
                        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="5.5" ry="2.86367051" />
                        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="4.5" ry="2.38636864" />
                      </g>
                      <g fill="#3FB1CE">
                        <path d="M27,13.5 C27,19.074644 20.250001,27.000002 14.75,34.500002 C14.016665,35.500004 12.983335,35.500004 12.25,34.500002 C6.7499993,27.000002 0,19.222562 0,13.5 C0,6.0441559 6.0441559,0 13.5,0 C20.955844,0 27,6.0441559 27,13.5 Z">
                        </path>
                      </g>
                      <g opacity="0.25" fill="#000000">
                        <path d="M13.5,0 C6.0441559,0 0,6.0441559 0,13.5 C0,19.222562 6.7499993,27 12.25,34.5 C13,35.522727 14.016664,35.500004 14.75,34.5 C20.250001,27 27,19.074644 27,13.5 C27,6.0441559 20.955844,0 13.5,0 Z M13.5,1 C20.415404,1 26,6.584596 26,13.5 C26,15.898657 24.495584,19.181431 22.220703,22.738281 C19.945823,26.295132 16.705119,30.142167 13.943359,33.908203 C13.743445,34.180814 13.612715,34.322738 13.5,34.441406 C13.387285,34.322738 13.256555,34.180814 13.056641,33.908203 C10.284481,30.127985 7.4148684,26.314159 5.015625,22.773438 C2.6163816,19.232715 1,15.953538 1,13.5 C1,6.584596 6.584596,1 13.5,1 Z">
                        </path>
                      </g>
                      <g transform="translate(6.0, 7.0)" fill="#FFFFFF" />
                      <g transform="translate(8.0, 8.0)">
                        <circle fill="#000000" opacity="0.25" cx="5.5" cy="5.5" r="5.4999962" />
                        <circle fill="#FFFFFF" cx="5.5" cy="5.5" r="5.4999962" />
                      </g>
                    </g>
                  </svg></div>
              </div>
              <div className="mapboxgl-control-container">
                <div className="mapboxgl-ctrl-top-left" />
                <div className="mapboxgl-ctrl-top-right">
                  <div className="mapboxgl-ctrl mapboxgl-ctrl-group"><button className="mapboxgl-ctrl-zoom-in" type="button" title="Zoom in" aria-label="Zoom in"><span className="mapboxgl-ctrl-icon" aria-hidden="true" /></button><button className="mapboxgl-ctrl-zoom-out" type="button" title="Zoom out" aria-label="Zoom out"><span className="mapboxgl-ctrl-icon" aria-hidden="true" /></button><button className="mapboxgl-ctrl-compass" type="button" title="Reset bearing to north" aria-label="Reset bearing to north"><span className="mapboxgl-ctrl-icon" aria-hidden="true" style={{transform: 'rotate(0deg)'}} /></button></div>
                </div>
                <div className="mapboxgl-ctrl-bottom-left">
                  <div className="mapboxgl-ctrl" style={{display: 'block'}}><a className="mapboxgl-ctrl-logo" target="_blank" rel="noopener nofollow" href="https://www.mapbox.com/" aria-label="Mapbox logo" /></div>
                </div>
                <div className="mapboxgl-ctrl-bottom-right">
                  <div className="mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-compact">
                    <div className="mapboxgl-ctrl-attrib-inner"><a href="https://www.mapbox.com/about/maps/" target="_blank" title="Mapbox" aria-label="Mapbox" role="listitem">© Mapbox</a> <a href="https://www.openstreetmap.org/about/" target="_blank" title="OpenStreetMap" aria-label="OpenStreetMap" role="listitem">© OpenStreetMap</a> <a className="mapbox-improve-map" href="https://apps.mapbox.com/feedback/?owner=mapbox&id=streets-v11&access_token=pk.eyJ1Ijoibm5hc3NpayIsImEiOiJjazliYm1uc3kwM2g4M21sM3R0c2xtZDM0In0.PfvOfzKBngHmVBGrSYmxMA" target="_blank" title="Improve this map" aria-label="Improve this map" role="listitem" rel="noopener nofollow">Improve this map</a></div>
                  </div>
                </div>
              </div>
            </div> */}
             <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14202.939952110339!2d-13.1728885!3d27.1331559!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xc370def0d2344f1%3A0x2818697e2178cf6c!2sPLATEFORME%20DES%20JEUNES%20LAAYOUNE!5e0!3m2!1sfr!2sma!4v1686596990819!5m2!1sfr!2sma"
             width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
             </div>
        </section>
        <section className="relative mb-7">
          <div className="max-w-[920px] mx-auto px-4 py-24">
            <div className="absolute top-0 right-0 bottom-0 left-0 lg:left-40 -z-10 h-full bg-[#ededed] rounded-r-[5rem]" />
            <div className="flex justify-center md:justify-start">
              <p className="inline bg-brand-yellow font-bold text-brand-black rounded text-xs py-0.5 px-1">إستمارة التواصل</p>
            </div>
            <h5 className="text-xl text-center md:text-start pt-6 pb-9">.سوف يسعدنا التواصل والرد على طلبكم في أسرع وقت ممكن</h5>
            <form action="#">
              <div className="md:w-1/2">
                <div className="pb-6">
                  <label className="block text-sm text-center md:text-start text-brand-slate mb-2">البريد الإلكتروني</label>
                  <div className="flex items-center">
                    <span className="w-10 h-10 flex items-center justify-center text-brand-slate bg-white border border-brand-light-slate rounded-r -ml-px">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-at-sign">
                        <circle cx={12} cy={12} r={4} />
                        <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94" />
                      </svg>
                    </span>
                    <input type="email" name="email" className="h-10 w-full bg-white border border-brand-light-slate rounded-r border-l-0 py-1.5 px-3" placeholder="nom@exemple.com" />
                  </div>
                </div>
                <div className="pb-6">
                  <label className="block text-sm text-center md:text-start text-brand-slate mb-2">الاسم الكامل</label>
                  <div className="flex items-center">
                    <span className="w-10 h-10 flex items-center justify-center text-brand-slate bg-white border border-brand-light-slate rounded-r -ml-px">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                    </span>
                    <input type="text" name="fullname" className="h-10 w-full bg-white border border-brand-light-slate rounded-r border-l-0 py-1.5 px-3" placeholder="Votre nom complet" />
                  </div>
                </div>
              </div>
              <div className="pb-6">
                <label className="block text-sm text-center md:text-start text-brand-slate mb-2">اكتب رسالتك هنا</label>
                <textarea name="content" cols={40} rows={10} className="w-full md:w-3/4 xl:w-full bg-white border border-brand-light-slate rounded-r border-l-0 py-1.5 px-3" placeholder="Votre message" required defaultValue={""} />
              </div>
              <div className="md:w-1/2">
                <button type="submit" className="text-white text-center bg-[#17A2B8] rounded w-full hover:bg-[#138496] transition ease-linear py-1.5 px-3">إرسال</button>
              </div>
            </form>
          </div>
        </section>

        <Footer />

        </div>
    );
    }
