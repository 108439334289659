import React from "react";
import Footer from "../components/footer";
import HeaderPages from "../components/headerpages";
import Header from "../components/header";
import Headerprogrames from "../components/headerprogrames";


function Programmes() {
    return (
        <div>
            <Headerprogrames />
            {/* <HeaderPages title="Programmes" description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatem." /> */}



            <Footer />
        </div>
    )
}

export default Programmes
